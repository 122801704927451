<template>
	<summary-infos></summary-infos>
</template>

<script>
	import SummaryInfos from '@/components/summary/summary-infos.vue'
	export default {
		components: {
			SummaryInfos
		}
	}
</script>

<style>
</style>
