<template>
	<div id="summary-infos-main">
		<div>
			<template v-if="loading">
				<el-skeleton v-for="_ in 5" :rows="6" animated />
			</template>
			<template v-else-if="hasErr">
				<el-result icon="error" title="错误提示" subTitle="页面初始化出现异常">
					<template slot="extra">
						<el-button type="primary" size="medium" @click="loadData">重新加载</el-button>
					</template>
				</el-result>
			</template>
			<template v-else>
				<div class="summary-infos-titles-container" style="overflow-x: auto;">
					<div class="summary-infos-titles">
						<el-radio-group v-model="selectedKey" size="mini">
							<el-radio-button v-for="value in values" :key="value.title" :label="value.title">
								{{value.title}}
							</el-radio-button>
						</el-radio-group>
					</div>
				</div>
				<el-card class="mb-2" v-loading="chartLoading">
					<div slot="header">
						<el-row :gutter="20">
							<el-col :md="12" :sm="24">
								<el-radio-group v-model="selectedTimeGroup" size="mini">
									<el-radio-button v-for="timeGroup in timeGroups" :key="timeGroup.key"
										:label="timeGroup.key">
										{{timeGroup.label}}
									</el-radio-button>
								</el-radio-group>
							</el-col>
							<el-col :sm="24" class="hidden-md-and-up">
								<div class="d-inline-block mr-1">
									<small>折扣后:</small><el-switch v-model="isNet"></el-switch>
								</div>
								
								<el-date-picker style="width:250px;" v-model="dates" type="daterange" align="right"
									start-placeholder="Start Date" end-placeholder="End Date" size="mini"
									value-format="yyyy-MM-dd" :clearable="false">
								</el-date-picker>
							</el-col>
							<el-col :md="12" class="text-right hidden-sm-and-down">
								<div class="d-inline-block mr-1">
									<small>折扣后:</small><el-switch v-model="isNet"></el-switch>
								</div>
								<el-date-picker style="width:250px;" v-model="dates" type="daterange" align="right"
									start-placeholder="Start Date" end-placeholder="End Date" size="mini"
									value-format="yyyy-MM-dd" :clearable="false">
								</el-date-picker>
							</el-col>
						</el-row>
					</div>
					<div style="overflow: auto;">
						<div id="summary-chart" :style="customStyle"></div>
					</div>
				</el-card>
				<el-row :gutter="20">
					<el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
						<el-card class="summary-infos-card">
							<div class="summary-infos-card__title">当日销售额</div>
							<div class="summary-infos-card__value">{{ selectedData && selectedData.data.rev0 || '--'}}
							</div>
							<div class="d-flex flex-row align-items-center">
								<div style="flex-basis:50%;">
									<span class="summary-infos-card__title">周同比</span>
									<i v-if="selectedData && selectedData.data.revDiff7.showIcon"
										:class="selectedData && selectedData.data.revDiff7.icon" class="pl-2"
										:style="{'color': selectedData && selectedData.data.revDiff7.iconColor}"></i>
									{{ selectedData && selectedData.data.revDiff7.percent || '--'}}%
								</div>
								<div style="flex-basis:50%;">
									<span class="summary-infos-card__title">日环比</span>
									<i v-if="selectedData && selectedData.data.revDiff1.showIcon"
										:class="selectedData && selectedData.data.revDiff1.icon" class="pl-2"
										:style="{'color': selectedData && selectedData.data.revDiff1.iconColor}"></i>
									{{ selectedData && selectedData.data.revDiff1.percent || '--'}}%
								</div>
							</div>
							<div class="summary-infos-card__total">
								L7D日均销售额 <span
									class="pl-1">${{ selectedData && selectedData.data.revAvg || '--' }}</span>
							</div>
						</el-card>
					</el-col>
					<el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
						<el-card class="summary-infos-card">
							<div class="summary-infos-card__title">当日ROI</div>
							<div class="summary-infos-card__value">{{ selectedData && selectedData.data.roi0 || '--' }}
							</div>
							<div class="d-flex flex-row align-items-center">
								<div style="flex-basis:50%;">
									<span class="summary-infos-card__title">周同比</span>
									<i v-if="selectedData && selectedData.data.roiDiff7.showIcon"
										:class="selectedData && selectedData.data.roiDiff7.icon" class="pl-2"
										:style="{'color': selectedData && selectedData.data.roiDiff7.iconColor}"></i>
									{{ selectedData && selectedData.data.roiDiff7.percent || '--'}}%
								</div>
								<div style="flex-basis:50%;">
									<span class="summary-infos-card__title">日环比</span>
									<i v-if="selectedData && selectedData.data.roiDiff1.showIcon"
										:class="selectedData && selectedData.data.roiDiff1.icon" class="pl-2"
										:style="{'color': selectedData && selectedData.data.roiDiff1.iconColor}"></i>
									{{ selectedData && selectedData.data.roiDiff1.percent || '--'}}%
								</div>
							</div>
							<div class="summary-infos-card__total">
								L7D日均ROI <span
									class="pl-1">{{ selectedData && selectedData.data.roiAvg || '--' }}</span>
							</div>
						</el-card>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4" class="d-none">
						<el-card class="summary-infos-card">
							<div class="summary-infos-card__title">当日客单价</div>
							<div class="summary-infos-card__value">42.12</div>
							<div class="d-flex flex-row align-items-center">
								<div style="flex-basis:50%;">
									<span class="summary-infos-card__title">周同比</span>
									<i class="el-icon-caret-top pl-2" style="color:green;"></i>
									12%
								</div>
								<div style="flex-basis:50%;">
									<span class="summary-infos-card__title">日环比</span>
									<i class="el-icon-caret-bottom pl-2" style="color:red;"></i>
									11%
								</div>
							</div>
							<div class="summary-infos-card__total">
								L7D日均客单 <span class="pl-1">$1234</span>
							</div>
						</el-card>
					</el-col>
				</el-row>
			</template>
		</div>
	</div>
</template>

<script>
	import Moment from 'moment'
	import * as echarts from 'echarts/core'
	import {
		TooltipComponent,
		GridComponent,
		ToolboxComponent,
		TitleComponent
	} from 'echarts/components'
	import {
		LineChart,
		BarChart
	} from 'echarts/charts'
	import {
		UniversalTransition
	} from 'echarts/features'
	import {
		CanvasRenderer
	} from 'echarts/renderers'
	echarts.use([
		TitleComponent,
		TooltipComponent,
		GridComponent,
		LineChart,
		BarChart,
		CanvasRenderer,
		UniversalTransition,
		ToolboxComponent
	]);
	import $Utils from '@/common/util'
	export default {
		data() {
			return {
				loading: false,
				hasErr: false,
				values: [],
				selectedKey: null,
				selectedTimeGroup: 'DAY',
				dates: [
					Moment().add(-30, 'days').format('YYYY-MM-DD'),
					Moment().add(-1, 'days').format('YYYY-MM-DD'),
				],
				charts: [],
				option: {},
				customStyle: 'width:600px;height:400px;',
				drawing: false,
				chartLoading: false,
				isNet: false
			}
		},
		mounted() {
			this.loadData()
		},
		computed: {
			selectedData() {
				return this.values.find(v => v.title === this.selectedKey)
			},
			selectedChart() {
				return this.charts.find(v => v.title === this.selectedKey)
			},
			timeGroups() {
				return [{
					label: '日',
					key: 'DAY'
				}, {
					label: '周',
					key: 'WEEK',
					format: 'YYYY-W'
				}, {
					label: '月',
					key: 'MONTH',
					format: 'YYYY-M'
				}, {
					label: '季',
					key: 'SEASON',
					format: 'YYYY-Q'
				}, {
					label: '年',
					key: 'YEAR',
					format: 'YYYY'
				}]
			},
			sourceData() {
				const data = this.selectedChart && this.selectedChart.data || []
				const timeGroup = this.timeGroups.find(v => v.key === this.selectedTimeGroup)
				if (timeGroup && timeGroup.key !== 'DAY') {
					const {format} = timeGroup
					// 聚合
					return data.reduce((lhs, rhs) => {
						const formattedDate = Moment(rhs.date).format(format)
						const previous = lhs.find(v => v.formattedDate === formattedDate)
						if (previous !== undefined) {
							Object.keys(rhs).forEach(key => {
								if (key === 'date') {
									previous.dates.push(rhs[key])
								} else if (key !== 'field') {
									previous[key] += rhs[key]
								} 
							})
						} else {
							lhs.push({
								...rhs,
								dates: [rhs.date],
								formattedDate
							})
						}
						return lhs
					}, []).map(v => {
						const dates = v.dates
						const startDate = dates[0]
						const endDate = dates[dates.length - 1]
						return {
							...v,
							date: `${startDate}~${endDate}` // v.formattedDate
						}
					})
				}
				return data
			},
			date() {
				return this.sourceData.map(v => v.date)
			},
			rev() {
				return this.sourceData.map(v => Math.round(v.revenue * 100) / 100)
			}
		},
		watch: {
			option(newVal, oldVal) {
				const myChart = echarts.getInstanceByDom(document.getElementById('summary-chart'))
				if (myChart) {
					myChart.setOption(newVal)
				}
			},
			selectedChart: {
				immediate: true,
				handler: 'drawChart'
			},
			selectedTimeGroup: {
				immediate: true,
				handler: 'drawChart'
			},
			dates: {
				immediate: true,
				handler: 'loadChartData'
			},
			isNet: {
				immediate: true,
				handler: 'drawChart'
			}
		},
		methods: {
			cost() {
				if (this.isNet) {
					return this.sourceData.map(v => Math.round(v.netCost * 100) / 100)
				}
				return this.sourceData.map(v => Math.round(v.cost * 100) / 100)
			},
			roi() {
				return this.sourceData.map(v => {
					var k = v.revenue / (this.isNet ? v.netCost : v.cost)
					return isNaN(k) || k === Infinity ? 0 : k.toFixed(2)
				})
			},
			async buildOptions() {
				return new Promise((resolve, reject) => {
					const date = this.date
					const cost = this.cost()
					const rev = this.rev
					const roi = this.roi()
					const lastDate = date[date.length - 1]
					const maxLeft = Math.max(...cost, ...rev)
					const maxRight = Math.max(...roi)
					const splitNumber = 6
					var leftInterval = $Utils.ceilIntervalNumber(maxLeft / splitNumber, maxLeft / splitNumber < 1 ? 0.1 : 1)
					var rightInterval = $Utils.ceilIntervalNumber(maxRight / splitNumber, 0.1)
					const that = this
					const option = {
						color: ['blue', 'green', 'black'],
						xAxis: [{
							type: "category",
							axisLine: {
								onZero: false
							},
							data: date,
							axisTick: {
								show: false,
								dataView: {
									show: true,
									readOnly: true,
								}
							},
							axisLabel: {
								formatter: (raw, index) => {
									return raw //date[index]
								},
								fontSize: 10
							}
						}],
						yAxis: [{
								type: "value",
								scale: false,
								axisLine: {
									show: false
								},
								splitNumber,
								interval: leftInterval,
								max: splitNumber * leftInterval
							},
							{
								type: "value",
								scale: false,
								axisLine: {
									show: false
								},
								splitNumber,
								interval: rightInterval,
								max: splitNumber * rightInterval,
								axisLabel: {
									formatter: (value) => {
										return value.toFixed(1)
									}
								}
							}
						],
						series: [{
								name: "Cost",
								type: "bar",
								data: cost
							},
							{
								name: "Rev",
								type: "bar",
								data: rev
							},
							{
								name: 'Roi',
								type: "line",
								data: roi,
								yAxisIndex: 1
							}
						],
						tooltip: {
							trigger: "axis",
							axisPointer: {
								type: 'cross',
								animation: false,
								label: {
									backgroundColor: '#505765'
								}
							},
							appendToBody: true
						},
						grid: {
							bottom: 50,
							top: 40,
							right: 60,
							left: 60
						},
						legend: {
						    data: ['Cost', 'Rev', 'Roi'],
							x:'center',
							y:'bottom',
						},
						title: {
						    text: '数据趋势',
						    left: 'left'
						}
					}
					resolve(option)
				})
			},
			clearChart() {
				var myChart = null
				try {myChart = echarts.getInstanceByDom(document.getElementById('summary-chart'))} catch (e) {}
				if (myChart) {
					myChart.clear()
					myChart.dispose()
				}
			},
			drawChart() {
				const el = document.getElementById('summary-chart')
				if (el) {
					if (this.drawing) return
					this.drawing = true
					this.clearChart()
					this.$nextTick(() => {
						const maxWidth = Math.max(this.date.length * 40, 600)
						this.customStyle = `width:${maxWidth}px;height:400px;`
						this.$nextTick(() => {
							echarts.init(el)
							this.buildOptions().then(option => this.option = option)
							this.drawing = false
						})
					})
				} else {
					setTimeout(() => {
						this.drawChart()
					}, 200)
				}
				
			},
			loadData() {
				this.loading = true
				this.hasErr = false
				const params = {
					groupType: 'WEBSITE',
					compareType: 'PREVIOUS',
					startDate: Moment().add(-7, 'days').format('YYYY-MM-DD'),
					endDate: Moment().format('YYYY-MM-DD')
				}
				this.$store.dispatch('fetchCharts', {
						params
					})
					.then((data) => {
						const summaryData = data[0]
						this.values = Object.keys(summaryData).map(title => {
							const value = summaryData[title]
							const l0 = value[0]
							const r0 = value[value.length - 1]
							const r1 = value[value.length - 2]
							// 当日销售额
							var rev0 = r0.revenue
							// 销售日环比
							var percent = 0
							if (parseInt(r0.revenue * 100) === 0 && parseInt(r1.revenue * 100) > 0) {
								percent = -100
							} else if (parseInt(r0.revenue * 100) > 0 && parseInt(r1.revenue * 100) === 0) {
								percent = 100
							} else if (parseInt(r0.revenue * 100) > 0 && parseInt(r1.revenue * 100) > 0) {
								percent = r0.revenue / r1.revenue * 100 - 100
							}
							var revDiff1 = {
								showIcon: parseInt(percent) !== 0,
								icon: percent > 0 ? 'el-icon-caret-top' : 'el-icon-caret-bottom',
								iconColor: percent > 0 ? 'green' : 'red',
								percent: Math.abs(percent).toFixed(0)
							}
							// 销售额周同比
							percent = 0
							if (parseInt(r0.revenue * 100) === 0 && parseInt(l0.revenue * 100) > 0) {
								percent = -100
							} else if (parseInt(r0.revenue * 100) > 0 && parseInt(l0.revenue * 100) === 0) {
								percent = 100
							} else if (parseInt(r0.revenue * 100) > 0 && parseInt(l0.revenue * 100) > 0) {
								percent = r0.revenue / l0.revenue * 100 - 100
							}
							var revDiff7 = {
								showIcon: parseInt(percent) !== 0,
								icon: percent > 0 ? 'el-icon-caret-top' : 'el-icon-caret-bottom',
								iconColor: percent > 0 ? 'green' : 'red',
								percent: Math.abs(percent).toFixed(0)
							}
							// ROI
							var rois = value.map(v => {
								if (v.revenue > 0 && v.cost > 0) {
									return v.revenue / v.cost
								}
								return 0
							})
							// 当日ROI
							var roi_r0 = rois[rois.length - 1]
							var roi_r1 = rois[rois.length - 2]
							var roi_l0 = rois[0]
							var roi0 = rois[rois.length - 1].toFixed(2)
							percent = 0
							if (parseInt(roi_r0 * 100) === 0 && parseInt(roi_l0 * 100) > 0) {
								percent = -100
							} else if (parseInt(roi_r0 * 100) > 0 && parseInt(roi_l0 * 100) === 0) {
								percent = 100
							} else if (parseInt(roi_r0 * 100) > 0 && parseInt(roi_l0 * 100) > 0) {
								percent = roi_r0 / roi_l0 * 100 - 100
							}
							var roiDiff1 = {
								showIcon: parseInt(percent) !== 0,
								icon: percent > 0 ? 'el-icon-caret-top' : 'el-icon-caret-bottom',
								iconColor: percent > 0 ? 'green' : 'red',
								percent: Math.abs(percent).toFixed(0)
							}
							percent = 0
							if (parseInt(roi_r0 * 100) === 0 && parseInt(roi_r1 * 100) > 0) {
								percent = -100
							} else if (parseInt(roi_r0 * 100) > 0 && parseInt(roi_r1 * 100) === 0) {
								percent = 100
							} else if (parseInt(roi_r0 * 100) > 0 && parseInt(roi_r1 * 100) > 0) {
								percent = roi_r0 / roi_r1 * 100 - 100
							}
							var roiDiff7 = {
								showIcon: parseInt(percent) !== 0,
								icon: percent > 0 ? 'el-icon-caret-top' : 'el-icon-caret-bottom',
								iconColor: percent > 0 ? 'green' : 'red',
								percent: Math.abs(percent).toFixed(0)
							}
							// 7日平均
							var l7d = value.slice(0, value.length - 1)
							var totalRev = l7d.reduce((l, r) => l + r.revenue, 0)
							var totalCost = l7d.reduce((l, r) => l + r.cost, 0)
							var revAvg = (totalRev / 7).toFixed(2)
							var roiAvg = 0
							if (totalRev > 0 && totalCost > 0) {
								roiAvg = (totalRev / totalCost).toFixed(2)
							}
							return {
								title,
								data: {
									rev0,
									revDiff1,
									revDiff7,
									revAvg,
									roi0,
									roiDiff1,
									roiDiff7,
									roiAvg,
								}
							}
						})
						this.selectedKey = this.values[0].title
						this.loadChartData()
					})
					.finally(() => {
						this.loading = false
						if (this.values.length === 0) {
							this.hasErr = true
						}
					})
			},
			loadChartData() {
				if (this.chartLoading) return
				this.chartLoading = true
				const params = {
					groupType: 'WEBSITE',
					compareType: 'PREVIOUS',
					startDate: this.dates[0],
					endDate: this.dates[1]
				}
				this.$store.dispatch('fetchCharts', {
						params
					})
					.then((data) => {
						const summaryData = data[0]
						this.charts = Object.keys(summaryData).map(title => {
							const value = summaryData[title]
							return {
								title,
								data: value
							}
						})
						this.chartLoading = false
					})
					.catch(err => {
						setTimeout(() => {
							this.loadChartData()
						}, 1000)
					})

			}
		}
	}
</script>

<style lang="scss">
	#summary-infos-main {
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		padding-bottom: 40px;
	}

	.summary-infos-titles-container {
		margin-bottom: 10px;

		.summary-infos-titles {
			width: max-content;
		}

		&::-webkit-scrollbar {
			height: 0;
		}
	}

	.summary-infos-card {
		margin-bottom: 10px;

		&__title {
			color: #ccc;
			font-size: 90%;
		}

		&__value {
			font-size: 200%;
		}

		&__total {
			padding-top: 10px;
			margin-top: 15px;
			border-top: 1px solid #ddd;
			font-size: 90%;
		}
	}
</style>
